import React, { FC, useState } from 'react'
import { FaRegImage, FaRegTimesCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import './pageTitleContent.scss'
import { Drawer } from 'antd'
import PhotoBorderHandler from '../PhotoHandler/photoBorderHandler'

interface IPageTitleContent {
  contentText: string;
  imageExample: string;
}

export const PageTitleContent: FC<IPageTitleContent> = ({ contentText, imageExample }) => {
  const { t } = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleDrawerBottomClose = () => { setIsDrawerOpen(false) }

  return (
    <>
      <div className="title-content__title">
        <div className="title-content__title-text">{ contentText }</div>
        <div className="title-content__title-example" onClick={() => {setIsDrawerOpen(true)}}>
          {`(${t('example').toLowerCase()}`} <FaRegImage className="title-content__title-icon"/>{')'}
        </div>
      </div>
      <Drawer
        title={<>{ contentText }<span className="title-content__example">{`(${t('example').toLowerCase()})`}</span></>}
        placement="bottom"
        onClose={handleDrawerBottomClose}
        open={isDrawerOpen}
        height={'auto'}
        closeIcon={<FaRegTimesCircle/>}
        className="drawer-opening"
      >
        <PhotoBorderHandler
          source={imageExample}
          buttonText={t('photo-module-button-text')}
        />
      </Drawer>
    </>
  )
}