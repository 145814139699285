import { atom } from 'recoil'

export const isGodModeState = atom<boolean>({
  key: 'isGodModeState',
  default: false,
  effects_UNSTABLE: [
    ({ setSelf }) => {
      const isGodModeEnabled = window.location.search.includes('isGodMode')
      setSelf(isGodModeEnabled)
    },
  ],
})