import React, { FC } from 'react'
import Chapter from '../../Layout/Chapter'
import WalkAround from '../../Assets/svg/walkaround.svg'

const PreInspection: FC = () => (
  <Chapter
    image={WalkAround}
    title="inspect-vehicle"
    description="chapter-description"
    hasImagePadding={true}
  />
)

export default PreInspection