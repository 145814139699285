import { atom, selector } from 'recoil'
import { getInspectionData } from '../../api/apis'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'
import { LocalStorageKeys } from '../../constants'
import { availableLanguagesSelector } from '..'


export const inspectionReasonTitleState = atom<string>({
  key: 'inspectionReasonTitleState',
  default: selector({
    key: 'inspectionReasonTitleState/default',
    get: async({ get }) => {
      const reasonTitle = localStorage.getItem(LocalStorageKeys.REASON)
      const inspectionId = get(inspectionIdSelector)
      if (reasonTitle) {
        return reasonTitle
      } else if (inspectionId) {
        const response = await getInspectionData(inspectionId, get(availableLanguagesSelector))
        return response?.typeLabel
      }
      return 'Inspection app'
    },
  }),
})