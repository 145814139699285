import { InspectionReason, InspectorRole, LocalStorageKeys } from '../constants'
import { useRecoilValue } from 'recoil'
import { inspectionReasonState } from '../Recoil'


export const useIsStartRentB2CFlow = () => {
  const flow = useRecoilValue(inspectionReasonState)

  const inspectionData = JSON.parse(localStorage.getItem(LocalStorageKeys.InspectionData))
  const isStartRentB2CFlow = flow === InspectionReason.START_RENT_CONTRACT
    && inspectionData?.inspectorRole === InspectorRole.CUSTOMER

  return { isStartRentB2CFlow }
}