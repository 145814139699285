import { atom, selector } from 'recoil'
import { getOdoMeterData } from '../../api/apis'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'

export type OdoMeterStateType = {
  id: number,
  image: string,
  mileage: number,
}

export const odoState = atom<OdoMeterStateType>({
  key: 'odoState',
  default: selector({
    key: 'odoState/default',
    get: ({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      if (inspectionId) {
        return getOdoMeterData(inspectionId)
      }
    },
  }),
})