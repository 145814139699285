import i18n from '../utils/i18n'

const months = [i18n.t('jan'), i18n.t('feb'), i18n.t('mar'),
  i18n.t('apr'), i18n.t('may'), i18n.t('jun'), i18n.t('jul'),
  i18n.t('aug'), i18n.t('sep'), i18n.t('oct'), i18n.t('nov'),
  i18n.t('dec'),
]

const summaryDate = () => {
  const newDate = new Date()
  return `${newDate.getDate()} ${months[newDate.getMonth()]} ${newDate.getFullYear()}`
}

export default summaryDate