import { Col, Progress, Row } from 'antd'
import { FC, useMemo, useState } from 'react'
import { FaArrowLeft, FaGlobe } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { CHAPTER_ROUTES, ROUTES, NoStepBackPages, FlowStepType } from '../../constants'
import { useRecoilValue } from 'recoil'
import { flowConfigState } from '../../Recoil'
import LanguageSelectorDrawer from '../../components/LanguageSelectorDrawer'
import styleVars from '../../styles/_branding-config.scss'
import './header.scss'

const Header: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [showLanguageSelector, setShowLanguageSelector] = useState<boolean>(false)
  const flowSteps = useRecoilValue(flowConfigState)
  const locationPath = pathname.substring(1)

  const isLandingPage = flowSteps?.[0]?.path === pathname.substring(1)

  const handleBackButtonClick = () => {
    setTimeout(() => { navigate(-1) }, 1000)
  }
  const handleLanguageSelectorOpen = () => {
    setShowLanguageSelector(true)
  }
  const handleLanguageSelectorClose = () => {
    setShowLanguageSelector(false)
  }

  const BackButton = () => (
    <Col span={3} className="header__left">
      { !NoStepBackPages.find(step => step === locationPath) &&
        <FaArrowLeft onClick={handleBackButtonClick} />
      }
    </Col>
  )
  const LanguageSelector = () => (
    <Col span={3} className="header__right">
      { isLandingPage && <FaGlobe onClick={handleLanguageSelectorOpen} /> }
    </Col>
  )

  const progressBarHeight = styleVars.progressBarHeight.split('px')[0]
  const isChapterRoute = CHAPTER_ROUTES.includes(locationPath)

  const pageTitle = useMemo(() => ROUTES.ROUTES_CONFIG.find(route =>
    route.path === locationPath || route.path === pathname.split('/')[1]
      || route.path === pathname.split('/')[1] + '/:id')?.title,
  [locationPath])

  const progressBarPercentage = flowSteps?.find((step: FlowStepType) =>
    step.path === pathname.substring(1))?.progressbar

  const headerClassName = () => {
    const HEADER_DEFAULT_CLASS_NAME = 'header'

    return isChapterRoute
      ? `${HEADER_DEFAULT_CLASS_NAME} ${HEADER_DEFAULT_CLASS_NAME}--chapter`
      : HEADER_DEFAULT_CLASS_NAME
  }

  return (
    <header className={headerClassName()}>
      <Row justify="space-between" align="middle" className="header__row">
        { BackButton() }
        <Col span={18} className="header__title">
          { pageTitle }
        </Col>
        { LanguageSelector() }
      </Row>
      { progressBarPercentage > 0 && !isChapterRoute &&
        <Progress
          className="header__progress"
          percent={progressBarPercentage}
          strokeWidth={+progressBarHeight}
          strokeColor={styleVars.progressBarColor}
          showInfo={false}
        />
      }
      <LanguageSelectorDrawer
        isOpen={showLanguageSelector}
        onClose={handleLanguageSelectorClose}
      />
    </header>
  )
}

export default Header
