import { Radio, RadioChangeEvent, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import './tbRadio.scss'

const ROOT_CLASS_NAME = 'tb-radio'

export type PropType = {
  id: number,
  title: string,
  subtitle?: string,
}

interface Props {
  list: Array<PropType>
  handleChange?: (selectedValue) => void,
  className?: string,
  defaultValue?: number
}

const TbRadio = ({ list, handleChange, className, defaultValue }: Props) => {
  const [value, setValue] = useState<number | undefined>(defaultValue)
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
    handleChange?.(e.target.value)
  }

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const classNames = className ? `${ROOT_CLASS_NAME} ${className}` : ROOT_CLASS_NAME
  return (
    <Radio.Group onChange={onChange} value={value} className={classNames}>
      <Space direction="vertical">
        {list.map((item) => (
          <Radio key={item.id} value={item.id}>
            <div>{item.title}</div>
            {item.subtitle && <div>{item.subtitle}</div>}
          </Radio>
        ),
        )}
      </Space>
    </Radio.Group>
  )
}

export default TbRadio