import { selector } from 'recoil'
import { getOptionsList } from '../../api/apis'
import * as Sentry from '@sentry/react'
import { v4 as uuidv4 } from 'uuid'
import { damageCategoryCode, keyOptionList } from '../../constants'
import { availableLanguagesSelector } from './availableLanguagesSelector'
import { inspectionIdSelector } from './inspectionIdSelector'

export type CategoryType = { id: string, code: string, displayText: string, cost?: string }

export const detailsListSelector = selector<CategoryType[]>({
  key: 'detailsListSelector',
  get: async({ get }) => {
    try {
      const optionList = await getOptionsList({
        keyValue: keyOptionList.CATEGORY,
        value: damageCategoryCode.DAMAGE_DETAIL,
        languageMap: get(availableLanguagesSelector),
        inspectionId: Number(get(inspectionIdSelector))
      }) ?? []

      return optionList.map(item => ({ id: uuidv4(), code: item.code, displayText: item.label }))
    } catch (err) {
      Sentry.captureException(err)
    }
  },
})