import i18n from '../utils/i18n'

export const VehicleDetailFields = {
  licensePlate: {
    id: 'licensePlate',
    key: 'license-plate',
  },
  brand : {
    id: 'brand',
    key: 'brand',
  },
  model: {
    id: 'model',
    key: 'model',
  },
  version: {
    id: 'version',
    key: 'version',
  },
  colorExterior: {
    id: 'colorExterior',
    key: 'color-exterior',
  },
  fuelType: {
    id: 'fuelTypeLabel',
    key: 'fuel-type',
  },
  gear: {
    id: 'gear',
    key: 'gear',
  },
  bodyWork: {
    id: 'bodyWork',
    key: 'bodywork',
  },
  colorInterior: {
    id: 'colorInterior',
    key: 'color-interior',
  },
  enginePower: {
    id: 'enginePower',
    key: 'engine-power',
  },
  chassisNumber: {
    id: 'chassisNumber',
    key: 'chassis-number',
  },
  options: {
    id: 'options',
    key: 'option-list',
  },
  accessories: {
    id: 'accessories',
    key: 'accessories',
  },
  dateFirstAdmission: {
    id: 'dateFirstAdmission',
    key: 'date-first-admission',
  },
  dateFirstIssuing: {
    id: 'dateFirstIssuing',
    key: 'date-first-issuing',
  },
}

export const vehicleDetailsConfig = [
  {
    id: 1,
    name: VehicleDetailFields.licensePlate.id,
    text: i18n.t(VehicleDetailFields.licensePlate.key),
  },
  {
    id: 2,
    name: VehicleDetailFields.brand.id,
    text: i18n.t(VehicleDetailFields.brand.key),
  },
  {
    id: 3,
    name: VehicleDetailFields.model.id,
    text: i18n.t(VehicleDetailFields.model.key),
  },
  {
    id: 4,
    name: VehicleDetailFields.version.id,
    text: i18n.t(VehicleDetailFields.version.key),
  },
  {
    id: 5,
    name: VehicleDetailFields.colorExterior.id,
    text: i18n.t(VehicleDetailFields.colorExterior.key),
  },
  {
    id: 6,
    name: VehicleDetailFields.fuelType.id,
    text: i18n.t(VehicleDetailFields.fuelType.key),
  },
  {
    id: 7,
    name: VehicleDetailFields.gear.id,
    text: i18n.t(VehicleDetailFields.gear.key),
  },
  {
    id: 8,
    name: VehicleDetailFields.bodyWork.id,
    text: i18n.t(VehicleDetailFields.bodyWork.key),
  },
  {
    id: 9,
    name: VehicleDetailFields.colorInterior.id,
    text: i18n.t(VehicleDetailFields.colorInterior.key),
  },
  {
    id: 10,
    name: VehicleDetailFields.enginePower.id,
    text: i18n.t(VehicleDetailFields.enginePower.key),
  },
  {
    id: 11,
    name: VehicleDetailFields.chassisNumber.id,
    text: i18n.t(VehicleDetailFields.chassisNumber.key),
  },
  {
    id: 12,
    name: VehicleDetailFields.options.id,
    text: i18n.t(VehicleDetailFields.options.key),
  },
  {
    id: 13,
    name: VehicleDetailFields.accessories.id,
    text: i18n.t(VehicleDetailFields.accessories.key),
  },
  {
    id: 14,
    name: VehicleDetailFields.dateFirstAdmission.id,
    text: i18n.t(VehicleDetailFields.dateFirstAdmission.key),
  },
  {
    id: 15,
    name: VehicleDetailFields.dateFirstIssuing.id,
    text: i18n.t(VehicleDetailFields.dateFirstIssuing.key),
  },
]

export const vehicleDetailsConfigDeprecated = [
  { id: 1, name: 'License plate', data: 'P-123-BD' },
  { id: 2, name: 'Brand', data: 'Alfa Romeo' },
  { id: 3, name: 'Model name', data: 'Guilia' },
  { id: 4, name: 'Color exterior', data: 'Rood' },
  { id: 5, name: 'Fuel type', data: 'Gasoline' },
  { id: 6, name: 'Transmission', data: 'Automatic' },
  { id: 7, name: 'Bodywork', data: 'Saloon' },
  { id: 8, name: 'Color Interior', data: 'Black' },
  { id: 9, name: 'Power', data: '201 pk' },
  { id: 10, name: 'Chassis', data: 'Automatic' },
  { id: 11, name: 'Lease object', data: 'Automatic' },
  { id: 12, name: 'Options', data: [
    'Quadrifoglio-sportstoelen bekleed met leder en alcantara',
    'Carbon interieurelementen op het middenconsole en de deurpanelen',
    '19" lichtmetalen velgen Performence Dark',
  ],
  },
  { id: 13, name: 'Accessories', data: [
    'Schakelflippers aan het stuur',
    'Privacy glass',
    'Bose audio systeem',
  ],
  },
  { id: 14, name: 'Date of 1st allowance', data: '01-02-2021' },
  { id: 15, name: 'Date of 1st registration', data: '01-02-2021' },
]