import { useMemo } from 'react'
import type { DamageType } from '../Recoil'
import { partition } from 'lodash-es'
import { DamageStatusType } from '../constants'

const useDamageList = (damageList: DamageType[]): [DamageType[] | [], DamageType[] | []] => {
  const [addByCustomerDamages, restDamages] = useMemo(() => {
    const [addedDamages, knowDamages] = partition(
      damageList,
      (damage) => damage.status.split(/\s*,\s*/).includes(DamageStatusType.CREATE_BY_CUSTOMER.status),
    )
    return [addedDamages, knowDamages]
  }, [damageList])

  return [addByCustomerDamages, restDamages]
}

export default useDamageList
