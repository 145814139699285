import { FC, ReactNode, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import TbButton from '../../components/TbButton'
import './location.scss'
import { Form } from 'antd'
import FormInput from '../../components/FormInput'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { errorMessageText, nextStepButton } from '../../helpers'
import {
  inspectionIdSelector, locationListState, userRoleSelector, flowConfigState
} from '../../Recoil'
import TbRadio from '../../components/TbRadio'
import { PropType } from '../../components/TbRadio'
import {
  getLocationInformationByInspectionId, updateLocation, getAddress, addLocation, updateStatus,
} from '../../api/apis'
import { debounce } from 'lodash-es'
import { FlowStepType, InspectorRole } from '../../constants'
import LocationForm from '../../components/LocationForm'
import { useNextStepNavigation, useStepDescription } from '../../hooks'

const UNKNOWN_LOCATION_ID = 0
const DUTCH_POSTCODE_REGEX = /^[1-9][0-9]{3}[a-zA-Z]{2}$/
const FETCH_ADDRESS_DEBOUNCE_TIME = 500

const Location: FC = () => {
  const { t } = useTranslation()
  const inspectionId = useRecoilValue(inspectionIdSelector)
  const [locations, setLocations] = useState<Array<PropType>>([])
  const [showForm, setShowForm] = useState(false)
  const [selectedLocationId, setSelectedLocationId] = useState(undefined)
  const [formContent, setFormContent] = useState<ReactNode>()
  const locationList = useRecoilValue(locationListState)
  const [isFormComplete, setIsFormComplete] = useState(true)
  const [isInternalEmployee, setIsInternalEmployee] = useState(false)
  const { pathname } = useLocation()
  const userRole = useRecoilValue(userRoleSelector)
  const flowSteps = useRecoilValue(flowConfigState)
  const goToNextStep = useNextStepNavigation()
  const { stepDescription } = useStepDescription()

  const currentStep = flowSteps.find((step: FlowStepType) => step.path === pathname.substring(1))

  useEffect(() => {
    setIsInternalEmployee(userRole === InspectorRole.INTERNAL_EMPLOYEE)
  }, [])

  type FormAttribeTypes = {
    zipCode: string;
    houseNumber: string;
    streetName: string;
    city: string;
  }

  const [form] = Form.useForm<FormAttribeTypes>()
  const zipCode = Form.useWatch('zipCode', form)
  const houseNumber = Form.useWatch('houseNumber', form)

  const handleSelectionChanged = (selectedValue) => {
    setSelectedLocationId(selectedValue)
  }

  useEffect(() => {
    if (selectedLocationId === UNKNOWN_LOCATION_ID) {
      setShowForm(true)
    } else {
      setShowForm(false)
    }
  }, [selectedLocationId])

  useEffect(() => {
    const fetchLocationInfo = async() => {
      const locationInfo = await getLocationInformationByInspectionId(inspectionId)
      if (locationInfo.data) {
        setSelectedLocationId(locationInfo.data.location)
        if (locationInfo.data.parkingSpace) {
          form.setFieldValue('parkingLot', locationInfo.data.parkingSpace)
        }
      }
    }
    if (inspectionId) {
      fetchLocationInfo()
    }
  }, [inspectionId])

  useEffect(() => {
    const locationListLocal:Array<PropType> = locationList.map(location => ({
      id: location.id,
      title: `${location.street} ${location.houseNumber}`,
      subtitle: `${location.postalCode}, ${location.city}`,
    }))
    const locationWithUnknownOption = locationListLocal.concat({
      id: UNKNOWN_LOCATION_ID,
      title: t('location-manual-address-option'),
    })

    setLocations(locationWithUnknownOption)
  }, [locationList])

  useEffect(() => {
    if (DUTCH_POSTCODE_REGEX.test(zipCode) && houseNumber) {
      const fetchAddress = () => getAddress({ zipCode, houseNumber }).then(response => {
        if (response.street && response.city) {
          form.setFieldsValue({ streetName: response.street, city: response.city })
        }
      })
      debounce(fetchAddress, FETCH_ADDRESS_DEBOUNCE_TIME)()
    }
  }, [zipCode, houseNumber])

  const handleSubmit = async() => {
    if (!selectedLocationId && !showForm) {
      setIsFormComplete(false)
    } else {
      form.submit()
      if (showForm) {
        if (zipCode && houseNumber) {
          await addLocation({
            postalCode: zipCode,
            houseNumber,
            street: form.getFieldValue('streetName'),
            city: form.getFieldValue('city'),
          }, +inspectionId)
        }
      } else {
        await updateLocation({
          inspectionId: +inspectionId,
          data: {
            location: selectedLocationId,
            parkingSpace: form.getFieldValue('parkingLot'),
          },
        })
      }
      if (currentStep?.shouldUpdateInspectionStatus && inspectionId !== undefined) {
        await updateStatus({ inspectionId: Number(inspectionId) })
      }
      goToNextStep()
    }
  }
  useEffect(() => {
    if (selectedLocationId) {
      setIsFormComplete(true)
    }
    if (locations) {
      if (showForm) {
        setIsFormComplete(true)
        setFormContent(
          <>
            <TbRadio className="location__address" list={locations} handleChange={handleSelectionChanged}></TbRadio>
            <LocationForm zipCode={zipCode} houseNumber={houseNumber} />
          </>,
        )
      } else {
        setFormContent(
          <>
            <TbRadio
              className="location__address"
              list={locations}
              handleChange={handleSelectionChanged}
              defaultValue={selectedLocationId} />
            { !isFormComplete && !showForm &&
              <p className="error-note">{ errorMessageText('location-validation') }</p>
            }
            { isInternalEmployee &&
              <FormInput
                labelText={t('parking-lot')}
                placeholder={t('parking-lot')}
                type="text"
                fieldName="parkingLot"
                isOptional
              />
            }
          </>,
        )
      }
    } else {
      setFormContent(<LocationForm zipCode={zipCode} houseNumber={houseNumber} />)
    }
  }, [locations, showForm, selectedLocationId, isFormComplete])

  return (
    <div className="location">
      <div className="location__text">{ t(stepDescription) }</div>
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        className="location__form"
        requiredMark={false}
      >
        { formContent }
      </Form>
      <TbButton
        type={nextStepButton({ flowSteps, path: pathname }).buttonType}
        onClick={handleSubmit}
      >
        { nextStepButton({ flowSteps, path: pathname }).buttonTitle }
      </TbButton>
    </div>
  )
}

export default Location
