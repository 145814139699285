import style from '../_branding-config.scss'

export default {
  '--primary-color': style.arvalPrimaryColor,
  '--secondary-color': style.arvalSecondaryColor,
  '--base-font-color': style.arvalBaseFontColor,
  '--primary-button-color': style.arvalSecondaryColor,
  '--secondary-button-color': style.arvalSecondaryColor,
  '--quinary-button-border-color': style.arvalSecondaryColor,
  '--base-header-color': style.arvalBaseFontColor,
  '--base-drawer-header-color': style.arvalSecondaryColor,
  '--base-feedback-title-color': style.arvalSecondaryColor,
  '--header-title-color': style.arvalBaseFontColor,
  '--chapter-header-color': style.arvalPrimaryColor,
  '--more-lines-title-color': style.arvalSecondaryColor,
  '--more-lines-dot-color': style.arvalSecondaryColor,
  '--progress-bar-color': style.arvalSecondaryColor,
  '--icons-header-color': style.arvalIconsHeaderColor,
  '--icons-text-color': style.arvalIconsTextColor,
  '--icons-button-color': style.arvalIconsButtonColor,
  '--primary-background-color': style.arvalPrimalyBackgroundColor,
  '--secondary-background-color': style.arvalSecondaryBackgroundColor,
  '--tertiary-background-color': style.arvalTertiaryBackgroundColor,
  '--page-background-position': style.arvalPageBackgroundPosition,
  '--page-background-border-radius': style.arvalPageBackgroundBorderRadius,
  '--page-background-transform': style.arvalPageBackgroundTransform,
  '--primary-font': style.arvalPrimaryFont,
  '--title-font': style.arvalTitleFont,
  '--form-label-color': style.arvalFormLabelColor,
  '--button-font': style.arvalButtonFont,
  '--primary-font-size': style.arvalPrimaryFontSize,
  '--button-font-size': style.arvalButtonFontSize,
  '--h4-font-size': style.arvalH4FontSize,
  '--base-font-weight': style.arvalBaseFontWeight,
  '--bold-font-weight': style.arvalBoldFontWeight,
  '--button-font-weight': style.arvalButtonFontWeight,
  '--button-text-margin': style.arvaButtonTextMargin,
  '--title-text-transform': style.arvalTitleTextTransform,
  '--button-text-transform': style.arvalButtonTextTransform,
  '--border-list-color': style.arvalBaseFontColor,
  '--instruction-page-background-color': style.arvalPrimaryColor,
}