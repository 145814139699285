export const IMAGES_BASE_URL = 'https://aipstaging.blob.core.windows.net/blob/application/NL-35250'

export const ImageLinksConfig = {
  THANK_YOU_IMAGE: `${IMAGES_BASE_URL}/illustration-thankYouImage.png`,
  WELCOME_PAGE_IMAGE: `${IMAGES_BASE_URL}/illustration-coverImage.png`,
  ODO_METER_EXAMPLE_IMAGE: `${IMAGES_BASE_URL}/illustration-odoMeter.jpg`,
  ODO_METER_INFO_IMAGE: `${IMAGES_BASE_URL}/illustration-instructions-InfoImageOdometer.jpg`,
  TIRES_EXAMPLE_IMAGE: `${IMAGES_BASE_URL}/illustration-tires.jpg`,
  DAMAGE_INFO_IMAGE_1: `${IMAGES_BASE_URL}/illustration-instructions-infoDamage1.png`,
  DAMAGE_INFO_IMAGE_2: `${IMAGES_BASE_URL}/illustration-instructions-infoDamage2.png`,
  ACCESSORIES_INFO_IMAGE: `${IMAGES_BASE_URL}/illustration-instructions-InfoImageAccessories.jpg`,
  VIDEO_INFO_IMAGE_1: `${IMAGES_BASE_URL}/illustration-instructions-infoVideo1.png`,
  VIDEO_INFO_IMAGE_2: `${IMAGES_BASE_URL}/illustration-instructions-infoVideo2.png`,
}
