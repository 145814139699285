const THEME_KEYS = {
  JL: 'jl',
  ARVAL: 'arval',
  DEFAULT: 'arval',
}

const ThemeConfig = {
  [THEME_KEYS.JL]: {
    title: 'JustLease',
  },
  [THEME_KEYS.ARVAL]: {
    title: 'Arval',
  },
  [THEME_KEYS.DEFAULT]: {
    title: 'Arval',
  },
}

export { THEME_KEYS, ThemeConfig }