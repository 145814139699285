import { atom, selector } from 'recoil'
import { getOverview } from '../../api/apis'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'

export const aspectVideoState = atom({
  key: 'aspectVideoState',
  default: selector({
    key: 'aspectVideoState/default',
    get: ({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      return inspectionId ? getOverview(inspectionId) : null
    },
  }),
})