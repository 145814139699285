import React, { FC } from 'react'
import { List } from 'antd'
import './flowStepsList.scss'


interface FlowStepsListProps {
  flowStepsList: string[],
  title?: string,
}

export const FlowStepsList: FC<FlowStepsListProps> = ({ flowStepsList, title }) => {

  return (
    <div className="flow-steps">
      <h4 className="flow-steps__title">{ title }</h4>
      <List
        itemLayout="horizontal"
        dataSource={flowStepsList}
        renderItem={(item, i) =>(
          <List.Item
            className="flow-steps__item"
            extra={ i < flowStepsList?.length - 1 ? <span className="flow-steps__metro-line"/> : <></>}
          ><List.Item.Meta
              avatar={<span>{ i + 1 }</span>}
              title={item}
            />
          </List.Item>
        )}
      >
      </List>
    </div>
  )
}

export default FlowStepsList
