import React, { FC } from 'react'
import './landscapeHint.scss'
import TurnLogo from '../../Assets/svg/turnScreenLandscape.svg'
import { useTranslation } from 'react-i18next'
import TbButton from '../TbButton'

interface Props {
  handleShowCamera:  (value: boolean) => void
  disabled?: boolean
}

const LandscapeHint: FC<Props> = ({ handleShowCamera, disabled }) => {
  const { t } = useTranslation()

  const closeCamera = () => {
    handleShowCamera(false)
  }

  return (
    <div className="orientation-container">
      <div className="orientation-container__over-layer">
        <div className="orientation-container__text-block">
          <img className="orientation-container__img" src={TurnLogo} alt=""/>
          <p className="orientation-container__text">{ t('camera-hint')}</p>
        </div>
      </div>
      <div className="orientation-container__controller">
        <TbButton className="orientation-container__cancel-button"
          disabled={disabled}
          type="link"
          onClick={closeCamera}>{t('camera-cancel')}
        </TbButton>
        <div className="orientation-container__fake-button"></div>
      </div>
    </div>
  )
}

export default LandscapeHint