import { useCallback, useEffect } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { isAuthenticatedState, tokenState } from '../../Recoil'
import localStorageKeys from '../../constants/localStorageKeys'
import { loginSuperUser } from '../../api/apis'
import { ResultStatus } from '../../constants'
import ThankYou from '../../Pages/ThankYou'

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(isAuthenticatedState)
  const [token, setToken] = useRecoilState(tokenState)
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get(localStorageKeys.InspectionId)

  const authenticate = useCallback(async() => {
    if (token) {
      setIsAuthenticated(true)
    } else {
      const response = await loginSuperUser()
      if (response?.status === ResultStatus.OK) {
        setIsAuthenticated(true)
        setToken(response.data.token)
      } else {
        setIsAuthenticated(false)
      }
    }
  }, [uuid])

  useEffect(() => {
    if (!isAuthenticated) {
      authenticate()
    }
  }, [uuid, isAuthenticated])

  if (isAuthenticated) {
    return <Outlet />
  }

  if (isAuthenticated === false) {
    return <ThankYou />
  }

  return null
}

export default ProtectedRoute