import { THEME_KEYS } from '../constants'

const changeFavicon = (CI: string): void => {
  document.querySelector('#manifest').setAttribute('href', `/${CI ?? THEME_KEYS.DEFAULT}-manifest.json`)
  document.querySelector('#favicon').setAttribute('href', `/images/${CI ?? THEME_KEYS.DEFAULT}Logo/favicon.ico`)
  document.querySelector('#apple-touch-icon-180').setAttribute('href',
    `/images/${CI ?? THEME_KEYS.DEFAULT}Logo/logo-180x180.png`)
  document.querySelector('#shortcut-icon').setAttribute('href', `/images/${CI ?? THEME_KEYS.DEFAULT}Logo/favicon.ico`)
}

export default changeFavicon