import { FlowStepType, ROUTES } from '../constants'
import i18n from 'i18next'

interface NextStepButtonOutput {
  isLastStep: boolean,
  buttonType: 'primary' | 'secondary',
  buttonTitle: string
}
export const nextStepButton = ({ flowSteps, path }): NextStepButtonOutput  => {
  const index = flowSteps?.findIndex((step: FlowStepType) => step.path === path.substring(1))
  if (flowSteps[index + 1]?.path === ROUTES.PUBLIC_PATH.THANK_YOU) {
    return {
      isLastStep: true,
      buttonType: 'secondary',
      buttonTitle: i18n.t('submit-inspection'),
    }
  } else if (flowSteps[index + 1]?.path === ROUTES.PRIVATE_PATH.SCAN_QR) {
    return {
      isLastStep: false,
      buttonType: 'secondary',
      buttonTitle: i18n.t('to-signing'),
    }
  } else {
    return {
      isLastStep: false,
      buttonType: 'primary',
      buttonTitle: i18n.t('continue-button-text'),
    }
  }
}
