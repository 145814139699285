import { atom, selector } from 'recoil'
import { getDamages } from '../../api/apis'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'
import { availableLanguagesSelector } from '../Selectors/availableLanguagesSelector'

export interface IDamageImages {
  id: string,
  image: string,
  labelText?: string
}

export type DamageType = {
  id: string,
  damagePart: { code: string, label: string },
  damageDetail: { code: string, label: string },
  repairType: { code: string, label: string },
  acceptable: boolean,
  repairCosts: number,
  status: string,
  shortage?: string | null,
  images: IDamageImages[],
}

export const damagesState = atom<DamageType[]>({
  key: 'damagesState',
  default: selector({
    key: 'damagesState/default',
    get: async({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      return inspectionId
        ? getDamages(inspectionId, get(availableLanguagesSelector))
        : []
    },
  }),
})