import React, { FC } from 'react'
import TbButton from '../../components/TbButton'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { accessoryChecks, inspectionIdSelector } from '../../Recoil'
import { getAccessories } from '../../api/apis'
import './accessories.scss'
import { useNextStepNavigation } from '../../hooks'
import { ImageLinksConfig } from '../../constants'

const Accessories: FC = () => {
  const { t } = useTranslation()
  const inspectionId = useRecoilValue(inspectionIdSelector)
  const setClientsAccessories = useSetRecoilState(accessoryChecks)
  const goToNextStep = useNextStepNavigation()

  const handleClickContinue = async() => {
    const res = await getAccessories(inspectionId)
    setClientsAccessories(res)
    goToNextStep()
  }

  return (
    <div className="accessories">
      <p>{ t('check-accessories-explanation') }</p>
      <p>{ t('info-accessories-explanation') }</p>
      <div className="accessories__image-holder">
        <img src={ImageLinksConfig.ACCESSORIES_INFO_IMAGE} alt="" className="accessories__image"></img>
      </div>
      <TbButton
        type="primary"
        onClick={handleClickContinue}>
        { t('continue-button-text') }
      </TbButton>
    </div>
  )
}

export default Accessories
