import { selector } from 'recoil'
import { getOptionsList } from '../../api/apis'
import * as Sentry from '@sentry/react'
import { keyOptionList } from '../../constants'
import { damageSectionCode } from '../../constants/optionConstantsList'
import { inspectionIdSelector } from './inspectionIdSelector'
import { availableLanguagesSelector } from './availableLanguagesSelector'

export const accessoryImagesSelector = selector({
  key: 'accessoryImagesSelector',
  get: async({ get }) => {
    try {
      return await getOptionsList({
        keyValue: keyOptionList.SECTION,
        value: damageSectionCode.IMAGES_STORAGE,
        languageMap: get(availableLanguagesSelector),
        inspectionId: Number(get(inspectionIdSelector))
      })
    } catch (err) {
      Sentry.captureException(err)
    }
  },
})