import { atom, selector } from 'recoil'
import { getInspectionData } from '../../api/apis'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'
import { availableLanguagesSelector } from '..'

export const inspectionStatusState = atom({
  key: 'inspectionStatusState',
  default: selector({
    key: 'inspectionStatusState/default',
    get: async({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      if (inspectionId) {
        const response = await getInspectionData(inspectionId, get(availableLanguagesSelector))
        return response?.status
      }
      return ''
    },
  }),
})