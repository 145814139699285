import { atom, selector } from 'recoil'
import { getInspectionData } from '../../api/apis'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'
import { availableLanguagesSelector } from '..'

export const inspectionReasonState = atom({
  key: 'inspectionReasonState',
  default: selector({
    key: 'inspectionReasonState/default',
    get: async({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      if (inspectionId) {
        const response = await getInspectionData(inspectionId, get(availableLanguagesSelector))
        return response?.type
      }
      return ''
    },
  }),
})