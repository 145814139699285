import { atom } from 'recoil'
import { LocalStorageKeys } from '../../constants'

export const themeState = atom<string>({
  key: 'themeState',
  default: '',
  effects_UNSTABLE: [
    ({ setSelf }) => {
      setSelf(localStorage.getItem(LocalStorageKeys.THEME))
    },
  ],
})