import { selector } from 'recoil'
import { getInspectionBasic } from '../../api/apis'
import { inspectionIdSelector } from './inspectionIdSelector'

export const inspectionBasicSelector = selector({
  key: 'inspectionBasicSelector',
  get: async({ get }) => {
    const inspectionId:string = get(inspectionIdSelector)
    if (!inspectionId) {
      return
    }
    const response = await getInspectionBasic(inspectionId)
    return response
  },
})