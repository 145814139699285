import { Route, Routes } from 'react-router-dom'
import Layout from '../Layout'
import { ROUTES, Orientation } from '../constants'
import PreInspection from '../Pages/PreInspection'
import VideoInspection from '../Pages/VideoInspection'
import ThankYou from '../Pages/ThankYou'
import React, { lazy, Suspense, useEffect } from 'react'
import Accessories from '../Pages/Accessories'
import Location from '../Pages/Location'
import Unauthorized from '../Pages/Unauthorized'
import ProtectedRoute from '../components/ProtectedRoute'
import Loading from '../components/Loading'
import Login from '../Pages/Login'
import { useSetRecoilState } from 'recoil'
import { orientationState } from '../Recoil'
import Welcome from '../Pages/Welcome'
import FindLicensePlate from '../Pages/FindLicensePlate'
import ProtectedContent from '../components/ProtectedContent'

const Damages = lazy(() => import(/* webpackChunkName: "Damages" */ '../Pages/Damages'))
const Interior = lazy(() => import(/* webpackChunkName: "Interior" */ '../Pages/Interior'))
const VehicleDetails = lazy(() => import(/* webpackChunkName: "VehicleDetails" */ '../Pages/VehicleDetails'))
const OdoMeter = lazy(() => import(/* webpackChunkName: "OdoMeter" */ '../Pages/OdoMeter'))
const Tires = lazy(() => import(/* webpackChunkName: "Tires" */ '../Pages/Tires'))
const AccessoriesCheck = lazy(() => import(/* webpackChunkName: "AccessoriesCheck" */ '../Pages/AccessoriesCheck'))
const OverviewPhoto = lazy(() => import(/* webpackChunkName: "OverviewPhoto" */ '../Pages/OverviewPhoto'))
const ScanQR = lazy(() => import(/* webpackChunkName: "ScanQR" */ '../Pages/ScanQR'))
const OverviewVideo = lazy(() => import(/* webpackChunkName: "OverviewVideo" */ '../Pages/OverviewVideo'))
const Summary = lazy(() => import(/* webpackChunkName: "Summary" */ '../Pages/Summary'))
const Exterior = lazy(() => import(/* webpackChunkName: "Exterior" */ '../Pages/Exterior'))

const AllRoutes = () => {
  const setOrientation = useSetRecoilState(orientationState)
  const updateOrientationMode = () => {
    if (window.innerWidth > window.innerHeight) {
      setOrientation(Orientation.LANDSCAPE)
    } else {
      setOrientation(Orientation.PORTRAIT)
    }
  }

  useEffect(() => {
    addEventListener('resize', updateOrientationMode)
    return () => {
      removeEventListener('resize', updateOrientationMode)
    }
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public routes */}
        <Route path={ROUTES.PUBLIC_PATH.UNAUTHORIZED} element={<Unauthorized />} />
        <Route path={ROUTES.PUBLIC_PATH.THANK_YOU} element={<ThankYou />} />

        {/* Protected routes */}
        <Route element={<Suspense fallback={<Loading />}><ProtectedRoute /></Suspense>}>
          <Route element={<ProtectedContent />}>
            <Route path="/" element={<Login />} />
            <Route path={ROUTES.PUBLIC_PATH.LOGIN} element={<Login />} />
            <Route path={ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW}
              element={
                <Suspense fallback={<Loading />}>
                  <Damages />
                </Suspense>
              }/>
            <Route path={ROUTES.PRIVATE_PATH.PRE_INSPECTION} element={<PreInspection />} />
            <Route path={ROUTES.PRIVATE_PATH.WELCOME} element={<Welcome />} />
            <Route path={ROUTES.PRIVATE_PATH.VIDEO_INSPECTION} element={<VideoInspection />} />
            <Route path={ROUTES.PRIVATE_PATH.ODO_METER} element={
              <Suspense fallback={<Loading />}><OdoMeter /></Suspense>}>
            </Route>
            <Route path={ROUTES.PRIVATE_PATH.INTERIOR_CHECK_STEP} element={
              <Suspense fallback={<Loading />}><Interior /></Suspense>}>
            </Route>
            <Route path={ROUTES.PRIVATE_PATH.EXTERIOR_CHECK_STEP} element={
              <Suspense fallback={<Loading />}><Exterior /></Suspense>}>
            </Route>
            <Route
              path={ROUTES.PRIVATE_PATH.VEHICLE_DETAILS}
              element={
                <Suspense fallback={<Loading />}>
                  <VehicleDetails />
                </Suspense>
              }
            >
            </Route>
            <Route
              path={ROUTES.PRIVATE_PATH.TIRES_OVERVIEW}
              element={
                <Suspense fallback={<Loading />}>
                  <Tires />
                </Suspense>
              }
            >
            </Route>
            <Route path={ROUTES.PRIVATE_PATH.ACCESSORIES} element={<Accessories />} />
            <Route path={ROUTES.PRIVATE_PATH.ACCESSORY_CHECK} element={
              <Suspense fallback={<Loading />}><AccessoriesCheck /></Suspense>} />
            <Route path={ROUTES.PRIVATE_PATH.LOCATION} element={<Location/>} />
            <Route
              path={ROUTES.PRIVATE_PATH.PHOTO_CHECKER_SHOT}
              element={
                <Suspense fallback={<Loading />}>
                  <OverviewPhoto />
                </Suspense>
              }
            >
            </Route>
            <Route
              path={ROUTES.PRIVATE_PATH.SCAN_QR}
              element={
                <Suspense fallback={<Loading />}>
                  <ScanQR/>
                </Suspense>
              }
            >
            </Route>
            <Route path={ROUTES.PRIVATE_PATH.VIDEO_CHECKER} element={
              <Suspense fallback={<Loading />}>
                <OverviewVideo />
              </Suspense> } >
            </Route>
            <Route
              path={ROUTES.PRIVATE_PATH.SUMMARY}
              element={
                <Suspense fallback={<Loading />}>
                  <Summary/>
                </Suspense>
              }
            >
            </Route>
            <Route
              path={ROUTES.PRIVATE_PATH.FIND_LICENSE_PLATE}
              element={
                <Suspense fallback={<Loading />}>
                  <FindLicensePlate />
                </Suspense> }
            ></Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default AllRoutes