
export const InspectionReason = {
  END_LEASE_CONTRACT: 0,
  BANKRUPTCY: 1,
  FREE_RETURN_ARRANGEMENT: 2,
  TAKEOVER: 3,
  TOTAL_LOSS: 4,
  REDEMPTION_LEAS_CONTRACT: 5,
  EARLY_TERMINATION: 6,
  REPORT_DAMAGE: 7,
  END_RENT_CONTRACT: 8,
  POOL_MANAGEMENT: 9,
  START_LEASE_CONTRACT: 10,
  START_RENT_CONTRACT: 11,
  START_RENT_B2C: 'START_RENT_B2C',
}