import { atom, selector } from 'recoil'
import { getLocations } from '../../api/apis'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'

export const locationListState = atom({
  key: 'locationListState',
  default: selector({
    key: 'locationListState/default',
    get: async({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      return inspectionId ? getLocations(Number(inspectionId)).then((res) => { return res.data?.locations }) : []
    },
  }),
})