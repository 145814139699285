import { BASE64_PREFIX, tiresNames } from '../constants'
import { CategoryType } from '../Recoil'
import { TiresType, TireType } from '../Recoil'

export interface ITiresData {
  error: [];
  extraTireSet: boolean;
  leftFront: TireType;
  leftRear: TireType;
  rightFront: TireType;
  rightRear: TireType;
  media: string;
  mediaBase64: { filename: string, data: string };
  rimType: string;
}

export const retrieveTiresData = (tiresBrandList: CategoryType[], data: ITiresData): TiresType => {
  const hasBrandInfo = (resource) => data?.[resource]?.brand
    && (Array.isArray(tiresBrandList) && tiresBrandList.length > 0)
  return {
    extraTireSet: data?.extraTireSet || false,
    mediaBase64: data?.mediaBase64
      ? { ...data?.mediaBase64, data: BASE64_PREFIX + data?.mediaBase64.data } : { filename: '', data: '' },
    rimType: data.rimType || null,
    tires: tiresNames.map(item => {
      return {
        name: item,
        type: data?.[item]?.type ?? null,
        brand: hasBrandInfo(item) ? tiresBrandList.find(el => el.code === data?.[item]?.brand) : null,
        remainingTreadDepth: data?.[item]?.remainingTreadDepth ?? null,
      }
    }),
  }
}