import { selectorFamily } from 'recoil'
import { getLeaseDetail } from '../../api/apis'
import { leaseIdSelector } from './leaseIdSelector'
import { availableLanguagesSelector } from './availableLanguagesSelector'

export const leaseDetailsSelector = selectorFamily({
  key: 'leaseDetailsSelector',
  get: (leaseId) => async({ get }) => {
    if (!leaseId) {
      return null
    }

    let localLeaseId
    if (leaseId) {
      localLeaseId = leaseId
    } else {
      localLeaseId = get(leaseIdSelector)
    }
    if (!localLeaseId) {
      return null
    }
    return getLeaseDetail(localLeaseId, get(availableLanguagesSelector))
  },
})