import { selector } from 'recoil'
import { getLeaseId } from '../../api/apis'
import { inspectionIdSelector } from './inspectionIdSelector'

export const leaseIdSelector = selector({
  key: 'leaseIdSelector',
  get: ({ get }) => {
    const inspectionId = get(inspectionIdSelector)
    if (!inspectionId) {
      return
    }

    return getLeaseId(inspectionId)
  },
})