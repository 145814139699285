import { useEffect } from 'react'
import LANGUAGE_COOKIE from '../constants/languageCookieName'
import { LocalStorageKeys, THEME_KEYS, ThemeConfig, TitleDivider } from '../constants'

const doNotDeleteList = [
  LANGUAGE_COOKIE,
  LocalStorageKeys.InspectionData,
  LocalStorageKeys.THEME,
]

const useClearLocalStorageAfterLoad = () => {
  useEffect(() => {
    document.title = 'Inspection app' + TitleDivider +
      `${ThemeConfig?.[localStorage.getItem(LocalStorageKeys.THEME)]?.title ?? ThemeConfig[THEME_KEYS.DEFAULT]?.title}`
    const allKeys = Object.keys(localStorage)
    const toBeDeleted = allKeys.filter(value => !doNotDeleteList.includes(value))
    toBeDeleted.forEach(value => { localStorage.removeItem(value) })
  }, [])
}

export default useClearLocalStorageAfterLoad
