import { selector } from 'recoil'
import { getLanguages } from '../../api/apis'
import * as Sentry from '@sentry/react'
import { inspectionIdSelector, tokenState } from '..'

export const availableLanguagesSelector = selector({
  key: 'availableLanguagesSelector',
  get: async({ get }) => {
    try {
      const token = get(tokenState)
      const inspectionId = get(inspectionIdSelector)
      if (token) {
        return await getLanguages(Number(inspectionId))
      }
    } catch (err) {
      Sentry.captureException(err)
    }
  },
})