import { selector } from 'recoil'
import { getOptionsList } from '../../api/apis'
import * as Sentry from '@sentry/react'
import { v4 as uuidv4 } from 'uuid'
import { damageCategoryCode, keyOptionList } from '../../constants'
import { CategoryType } from './detailsListSelector'
import { availableLanguagesSelector } from './availableLanguagesSelector'
import { inspectionIdSelector } from './inspectionIdSelector'

export const repairTypeListSelector = selector<CategoryType[]>({
  key: 'repairTypeListSelector',
  get: async({ get }) => {
    try {
      const optionList = await getOptionsList({
        keyValue: keyOptionList.CATEGORY,
        value: damageCategoryCode.REPAIR_TYPE,
        languageMap: get(availableLanguagesSelector),
        inspectionId: Number(get(inspectionIdSelector))
      })

      return optionList.map(item => ({ id: uuidv4(), code: item.code, displayText: item.label, cost: item.cost }))
    } catch (err) {
      Sentry.captureException(err)
    }
  },
})