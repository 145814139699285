import { atom } from 'recoil'

interface IGeneralTireDataState {
  type: string | null, brand: { id: string | null, code: string | null, displayText: string | null }
}

export const initialGeneralTireData = {
  type: null, brand: { id: null, code: null, displayText: null },
}

export const generalTireDataState = atom<IGeneralTireDataState>({
  key: 'generalTireDataState',
  default: initialGeneralTireData,
})