export default {
  InspectionId: 'inspectionid',
  UUID: 'uuid',
  RequiredAspects: 'requiredAspects',
  InspectionData: 'InspectionData',
  THEME: 'theme',
  TOKEN: 'token',
  CUSTOMER_SIGNING: 'customer-self-signing',
  REASON: 'reason',
  RESET: 'reset',
}