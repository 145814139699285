import FormInput from '../FormInput'
import React from 'react'
import { useTranslation } from 'react-i18next'

const LocationForm = ({ zipCode, houseNumber }) => {
  const { t } = useTranslation()

  return (
    <>
      <FormInput
        labelText={t('postal-code')}
        placeholder={t('postal-code')}
        type="text"
        rules={[{ required: true, message: t('zip-code-validation-msg') }]}
        fieldName="zipCode"
        value={zipCode}
      />
      <FormInput
        labelText={t('house-number')}
        placeholder={t('house-number')}
        type="text"
        value={houseNumber}
        rules={[{ required: true, message: t('house-number-validation-msg') }]}
        fieldName="houseNumber"
      />
      <FormInput
        labelText={t('street-name')}
        placeholder={t('street-name')}
        type="text"
        rules={[{ required: true, message: t('invalid-street-name') }]}
        fieldName="streetName"
      />
      <FormInput
        labelText={t('city')}
        placeholder={t('city')}
        type="text"
        rules={[{ required: true, message: t('invalid-city') }]}
        fieldName="city"
      />
    </>
  )
}
export default LocationForm