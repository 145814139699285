import React, { FC, useEffect, useRef, useState, useCallback, memo } from 'react'
import Webcam from 'react-webcam'
import TbButton from '../../components/TbButton'
import { FaCamera, FaRegComment, FaComment } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { cameraModuleDisplayState, orientationState } from '../../Recoil'
import LandscapeHint from '../LandscapeHint'
import './videoWebcamShot.scss'
import { photoResolution, Orientation } from '../../constants'
import Loading from '../Loading'
import { imageConfirmInfoState, showImageConfirmState } from '../../Recoil'
import { ImageConfirmation } from '../ImageConfirmation'
import { InfoBalloon } from '../InfoBalloon'

interface Props {
  updateImage: (value: string, id?: string) => void
  helpText?: string
  className?: string
  buttonText?: string
  name?: string
  imageState?: string
  resetCounter?: number
  cameraId?: string
  hasOnlyButton?: boolean
  hasImageConfirmation?: boolean
  imageConfirmCloseHandel?: () => void
  hasCancelButton?: boolean
  cameraBalloonInfo?: string
  photoMask?: string | null
  photoIcon?: string | null
}

const VideoWebcamShot: FC<Props> = ({
  updateImage,
  helpText,
  buttonText,
  imageState,
  resetCounter,
  cameraId,
  hasOnlyButton,
  hasImageConfirmation,
  imageConfirmCloseHandel,
  hasCancelButton,
  cameraBalloonInfo,
  photoMask,
  photoIcon = null,
}) => {
  const { t } = useTranslation()
  const [image, setImage] = useState<string>(null)
  const [showCamera, setShowCamera] = useRecoilState(cameraModuleDisplayState(cameraId))
  const webcamRef = useRef(null)
  const [isLoadingCam, setIsLoadingCam] = useState(true)
  const screenOrientation = useRecoilValue(orientationState)
  const [showImageConfirm, setShowImageConfirm] = useRecoilState(showImageConfirmState)
  const imageInfo = useRecoilValue(imageConfirmInfoState)
  const [hasInfoBalloon, setHasInfoBalloon] = useState(false)
  const takePhoto = useCallback(async() => {
    const imageSource = webcamRef.current.getScreenshot({
      width: photoResolution.idealWidth, height: photoResolution.idealHeight,
    })
    setImage(imageSource)
    setShowImageConfirm(true)
    if (cameraId) {
      updateImage(imageSource, cameraId)
    } else {
      updateImage(imageSource)
    }
    setShowCamera(false)
  }, [webcamRef])

  useEffect(() => {
    return () => {
      setShowCamera(false)
    }
  }, [])

  useEffect(() => {
    if (showCamera) {
      if (cameraBalloonInfo) { setHasInfoBalloon(true) }
      setTimeout(() => {
        setIsLoadingCam(false)
      }, 2000)
    } else {
      setIsLoadingCam(true)
    }
  }, [showCamera])

  useEffect(() => {
    let balloonTimeout
    if (cameraBalloonInfo && hasInfoBalloon && showCamera) {
      balloonTimeout = setTimeout(() => {setHasInfoBalloon(false)}, 5000)
    }
    return () => clearTimeout(balloonTimeout)
  }, [hasInfoBalloon, showCamera])

  useEffect(() => {
    if (resetCounter) {
      setImage('')
    }
  }, [resetCounter])

  const videoConstraints = {
    facingMode: 'environment',
    width: { min: photoResolution.minWidth, ideal: photoResolution.idealWidth },
    height: { min: photoResolution.minHeight, ideal: photoResolution.idealHeight },
  }

  const handleShowCamera = (shouldShowCamera: boolean) => {
    if (shouldShowCamera && showImageConfirm)
      setShowImageConfirm(false)
    setShowCamera(shouldShowCamera)
  }

  const imageConfirmClose = () => {
    imageConfirmCloseHandel()
  }

  const UploadButton = (
    <>
      { hasOnlyButton &&
        <TbButton
          type={helpText === t('optional-picture') ? 'link' : 'quinary'}
          onClick={()=>handleShowCamera(true)}
          icon={<FaCamera />}
        >
          {buttonText}
        </TbButton>
      }
      {helpText && <p className="help-text">{helpText}</p>}
    </>
  )
  const imageElements = (pic) => (
    <>
      { imageInfo && hasImageConfirmation && showImageConfirm &&
        <ImageConfirmation
          imageInfo={imageInfo}
          imageConfirmClose={imageConfirmClose}
          handleShowCamera={() => handleShowCamera(true)}
          image={pic} />
      }
      <div onClick={() => handleShowCamera(true)}>
        <img src={pic} alt="" className="camera-shot__photo"/>
        {helpText && <p className="help-text">{helpText}</p>}
      </div>
    </>
  )

  return (
    <div className="camera-shot">
      { showCamera &&
        <>
          { screenOrientation === Orientation.LANDSCAPE &&
          <div className="camera-shot__over-layer">
            { cameraBalloonInfo && hasInfoBalloon &&
              <InfoBalloon cameraBalloonInfo={cameraBalloonInfo} />
            }
            <div className="camera-shot__controller">
              { photoIcon ?
                <div className="camera-shot__icon">
                  <img className="camera-shot__img-icon" src={photoIcon} alt=""/>
                </div> :
                <div className="camera-shot__info-icon">
                  { cameraBalloonInfo &&
                    <>
                      { hasInfoBalloon ?
                        <FaComment onClick={() => setHasInfoBalloon(false)}/> :
                        <FaRegComment onClick={() => setHasInfoBalloon(true)}/>
                      }
                    </>
                  }
                </div>
              }
              <TbButton
                disabled={isLoadingCam}
                onClick={takePhoto}
                className="camera-shot__camera-button"
              >{<FaCamera/>}</TbButton>
              { hasCancelButton &&
                <TbButton type="link"
                  className="camera-shot__cancel-button"
                  disabled={ isLoadingCam }
                  onClick={() => handleShowCamera(false)}>{t('camera-cancel')}
                </TbButton>
              }
            </div>
            { photoMask && !isLoadingCam &&
            <div className="camera-shot__photo-mask"><img src={photoMask} alt="" /></div>}
          </div>
          }
          { screenOrientation === Orientation.PORTRAIT &&
            <LandscapeHint handleShowCamera={handleShowCamera} disabled={ isLoadingCam }/>
          }
          { isLoadingCam &&
            <>
              {screenOrientation === Orientation.LANDSCAPE &&
                <div className="camera-shot__loader">
                  <Loading/>
                </div>
              }
              <div className="camera-shot__video-cover"></div>
            </>
          }
          <Webcam
            ref={ webcamRef }
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            mirrored={ false }
            className={`camera-shot__video ${screenOrientation}`}
            screenshotQuality={1}
            forceScreenshotSourceSize
          />
        </>
      }
      { (imageState ?? image)
        ? imageElements(imageState ?? image)
        : UploadButton }
    </div>
  )
}

export default memo(VideoWebcamShot)