import { FC, HTMLInputTypeAttribute } from 'react'
import { Form, Input } from 'antd'
import './FormInput.scss'
import { Rule } from 'antd/lib/form'
import { useTranslation } from 'react-i18next'

interface Props {
  labelText: string
  onChange?: (value) => void
  placeholder?: string
  type: HTMLInputTypeAttribute
  disabled?: boolean
  value?: string
  fieldName?: string,
  rules?: Rule[],
  isOptional?: boolean
  validateTrigger?: string | string[]
}

const FormInput: FC<Props> = ({
  labelText,
  onChange,
  placeholder,
  type,
  value,
  disabled,
  fieldName,
  rules,
  isOptional,
  validateTrigger = 'onChange',
}) => {
  const { t } = useTranslation()
  const getLabel = () => {
    if (labelText) {
      if (isOptional) {
        return <div className="optional-block">
          <span className="label">{labelText}</span>
          <span className="is-optional">({t('optional')})</span>
        </div>
      }
    }

    return labelText
  }
  return (
    <div className="form-input">
      <Form.Item
        label={getLabel()}
        className="form-input__container"
        name={fieldName}
        rules={rules}
        shouldUpdate
        validateTrigger={validateTrigger}
      >
        <Input
          type={type}
          min={ type === 'number' ? '0' : null }
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          value={value}
        />
      </Form.Item>
    </div>
  )
}

export default FormInput