import React, { useCallback, useState } from 'react'
import './findLicensePlate.scss'
import { useTranslation } from 'react-i18next'
import TbButton from '../../components/TbButton'
import { nextStepButton } from '../../helpers'
import { useLocation } from 'react-router-dom'
import { useNextStepNavigation } from '../../hooks'
import TbRadio from '../../components/TbRadio'
import FormInput from '../../components/FormInput'
import { Form } from 'antd'
import VideoWebcamShot from '../../components/VideoWebcamShot'
import PhotoBorderHandler from '../../components/PhotoHandler/photoBorderHandler'
import { PageTitleContent } from '../../components/PageTitleContent'
import { cameraIdNames, ImageLinksConfig } from '../../constants'
import { useRecoilValue } from 'recoil'
import { flowConfigState } from '../../Recoil'

const SCAN_UPLOAD_ID = 0
const MANUAL_UPLOAD_ID = 1

const FindLicensePlate:React.FC = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const [image, setImgBase64] = useState(null)
  const goToNextStep = useNextStepNavigation()
  const [selectedOptionId, setSelectedOptionId] = useState<number>(MANUAL_UPLOAD_ID)
  const [form] = Form.useForm()
  const flowSteps = useRecoilValue(flowConfigState)

  const handleSelectionChanged = useCallback((selectedId) => {
    setSelectedOptionId(selectedId)
  }, [])

  const lookupLicenseTypes = [
    { id: SCAN_UPLOAD_ID, title: t('upload-license-plate-first-option-text') },
    { id: MANUAL_UPLOAD_ID, title: t('upload-license-plate-second-option-text') },
  ]

  const handelUpload = useCallback((img) => {
    if (img) {
      setImgBase64(img)
    }
  }, [])

  return (
    <div className="find-license-plate">
      <p>{ t('find-license-plate-explanation') }</p>
      <TbRadio
        className="location__address"
        list={lookupLicenseTypes}
        handleChange={handleSelectionChanged}
        defaultValue={MANUAL_UPLOAD_ID} />
      { selectedOptionId === MANUAL_UPLOAD_ID
        ? (
          <Form
            layout="vertical"
            form={form}
            initialValues={{ licensePlate: null }}>
            <FormInput
              labelText={t('find-license-plate-input-label')}
              placeholder={t('find-license-plate-input-placeholder')}
              type="text"
              fieldName="licensePlate"
            />
          </Form>
        ) : (
          <>
            <PageTitleContent
              contentText={t('license-plate-image-title')}
              imageExample={ImageLinksConfig.ODO_METER_EXAMPLE_IMAGE}
            />
            { !image &&
              <PhotoBorderHandler
                source={ImageLinksConfig.ODO_METER_EXAMPLE_IMAGE}
                buttonText={t('photo-module-button-text')}
                cameraId={cameraIdNames.FIND_LICENSE_PLATE_STATE_ID}
              />
            }
            <VideoWebcamShot
              updateImage={handelUpload}
              name="odo-overview"
              helpText={t('scan-license-plate-scan-image-help-text')}
              imageState={image}
              cameraId={cameraIdNames.FIND_LICENSE_PLATE_STATE_ID}
              hasCancelButton
            />
          </>)
      }
      <TbButton
        type={nextStepButton({ flowSteps, path: pathname }).buttonType}
        onClick={goToNextStep}
      >
        { nextStepButton({ flowSteps, path: pathname }).buttonTitle }
      </TbButton>
    </div>
  )
}

export default FindLicensePlate
