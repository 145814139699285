import { atom, selector } from 'recoil'
import { getAccessories } from '../../api/apis'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'

export interface IAccessoriesChecks {
  damage: number | null;
  item: string;
  media: number;
  check: number | null;
}

export const accessoryChecks = atom<IAccessoriesChecks[]>({
  key: 'accessoryChecks',
  default: selector({
    key: 'accessoryChecks/default',
    get: async({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      const response = await getAccessories(inspectionId)
      return response ?? []
    },
  }),
})