import { v4 as uuidv4 } from 'uuid'


export const damageDefault = {
  id: '',
  damagePart: { code: '', label: '' },
  damageDetail: { code: '', label: '' },
  repairType: { code: '', label: '' },
  acceptable: undefined,
  repairCosts: undefined,
  images: [
    { id: uuidv4(), image: '', labelText: 'Overview picture' },
    { id: uuidv4(), image: '', labelText: 'Detail picture' },
  ],
}
