import { selector } from 'recoil'
import * as Sentry from '@sentry/react'
import { v4 as uuidv4 } from 'uuid'
import { CategoryType } from './detailsListSelector'
import { tireConfigSelector } from './tireConfigSelector'
import { damageCategoryCode } from '../../constants'

export const tireBrandListSelector = selector<CategoryType[]>({
  key: 'tireBrandListSelector',
  get: async({ get }) => {
    try {
      const tireOptions = get(tireConfigSelector)
      const brands = tireOptions.filter(option => option.category === damageCategoryCode.TIRE_BRAND)
      return brands.map(item => ({ id: uuidv4(), code: item.code, displayText: item.label }))
    } catch (err) {
      Sentry.captureException(err)
    }
  },
})