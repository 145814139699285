import { Divider, Drawer } from 'antd'
import TbButton from '../TbButton'
import { FaCheck, FaRegTimesCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { FC, Fragment, useState } from 'react'
import { languageChecker } from '../../helpers'
import { FeatureFlags, LANGUAGE_COOKIE } from '../../constants'
import { useFeatureFlag } from 'configcat-react'
import { availableLanguagesSelector } from '../../Recoil'
import './languageSelectorDrawer.scss'
import { useRecoilValue } from 'recoil'

interface DrawerContentProps {
  isOpen: boolean;
  onClose: () => void;
}

const LanguageSelectorDrawer: FC<DrawerContentProps> = ({ isOpen = false, onClose }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageChecker(localStorage.getItem(LANGUAGE_COOKIE)),
  )
  const { t } = useTranslation()
  const {
    value: isGermanLanguageEnabledValue,
  } = useFeatureFlag(FeatureFlags.is_german_language_enabled, true)
  const availableLanguages = useRecoilValue(availableLanguagesSelector)
  const handleLanguageChange = (locale) => {
    localStorage.setItem(LANGUAGE_COOKIE, locale)
    window.location.reload()
  }

  const isLanguageSelected = (targetLanguage) =>
    targetLanguage === selectedLanguage

  const languageText = (displayName) => displayName?.split(' ')?.[0]
  return (
    <Drawer
      title={t('language-selector-title')}
      placement="bottom"
      closeIcon={<FaRegTimesCircle/>}
      open={isOpen}
      onClose={onClose}
      className="drawer-opening"
    >
      <div className="language-selector__language-section">
        { availableLanguages?.map((language, index) => {
          if (language.iso2 === 'de' && !isGermanLanguageEnabledValue) {
            return undefined
          }
          return (
            <Fragment key={language.displayName}>
              <div
                className="language-selector__language-wrapper"
                onClick={() => setSelectedLanguage(language.code.toLowerCase())}>
                <div className="language-selector__check-icon-holder">
                  { isLanguageSelected(language.code.toLowerCase()) && <FaCheck/> }
                </div>
                <p>{ languageText(language.displayName) }</p>
              </div>
              { index !== availableLanguages.length - 1 && <Divider/> }
            </Fragment>
          )
        })}
      </div>
      <TbButton className="language-selector__language-save" onClick={() => handleLanguageChange(selectedLanguage)}>
        { t('save') }
      </TbButton>
    </Drawer>
  )
}

export default LanguageSelectorDrawer