import { InspectionReason } from './inspectionReason'
import { ROUTES } from './index'

// TODO: this map is not useful anymore. retrieving flow in the flows.ts file
// can use InspectionReason number as key directly. Refactor the following
export const FlowTypeMap = {
  EndLeaseContractFlow: InspectionReason.END_LEASE_CONTRACT,
  EndRentContractFlow: InspectionReason.END_RENT_CONTRACT,
  StartLeaseContractFlow: InspectionReason.START_LEASE_CONTRACT,
  StartRentContractFlow: InspectionReason.START_RENT_CONTRACT,
}

export const NoStepBackPages = [
  ROUTES.PUBLIC_PATH.THANK_YOU,
  ROUTES.PRIVATE_PATH.WELCOME,
  ROUTES.PRIVATE_PATH.SCAN_QR,
  ROUTES.PRIVATE_PATH.FIND_LICENSE_PLATE,
]