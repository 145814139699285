import { Form, Radio, RadioChangeEvent } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { memo, useState } from 'react'
import NegativeEmotion from '../../Assets/svg/negativeEmotion.svg'
import PositiveEmotion from '../../Assets/svg/positiveEmotion.svg'
import TbButton from '../TbButton'
import { useTranslation } from 'react-i18next'
import './feedback.scss'
import { useNextStepNavigation } from '../../hooks'

const TEXT_AREA_ROWS = 7
const TEXT_AREA_MAX_TEXT = 500

const Feedback = () => {
  const { t } = useTranslation()
  const [, setFeedback] = useState('')
  const [hasSentFeedback, setHasSentFeedback] = useState(false)
  const goToNextStep = useNextStepNavigation()
  const handleFeedbackChange = (e: RadioChangeEvent) => {
    setFeedback(e.target.value)
  }

  const handleSendFeedback = () => {
    if (hasSentFeedback) {
      goToNextStep()
    } else {
      setHasSentFeedback(true)
    }
  }

  const optionalLabel = (
    <>
      <span>{t('global-remarks')}</span>
      <span className="feedback__optional-label">({t('optional')})</span>
    </>
  )

  const feedbackForm = () => (
    <div className="feedback__form">
      <h4 className="feedback__title">{ t('how-did-you-experience') }</h4>
      <Form layout="vertical" requiredMark={false}>
        <Form.Item required label={t('your-experience')}>
          <Radio.Group className="feedback__options" onChange={handleFeedbackChange}>
            <Radio.Button value="bad" className="feedback__option">
              <div className="feedback__emotion">
                <img src={NegativeEmotion} className="feedback__emotion-img" alt=""/>
                <div className="feedback__option-text">{ t('not-good') }</div>
              </div>
            </Radio.Button>
            <Radio.Button value="good" className="feedback__option">
              <div className="feedback__emotion">
                <img src={PositiveEmotion} className="feedback__emotion-img" alt="" />
                <div className="feedback__option-text">{ t('positive') }</div>
              </div>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={optionalLabel} name="remark">
          <TextArea
            autoSize={{ minRows: TEXT_AREA_ROWS, maxRows: TEXT_AREA_ROWS }}
            placeholder={t('type-your-remarks')}
            maxLength={TEXT_AREA_MAX_TEXT}
          />
        </Form.Item>
      </Form>
    </div>
  )

  return (
    <div className="feedback">
      {hasSentFeedback
        ? <h4 className="feedback__title">{t('thanks-for-feedback')}</h4>
        : <>
          { feedbackForm() }
          <TbButton
            type="primary"
            onClick={handleSendFeedback}>
            { t('submit-feedback') }
          </TbButton>
        </>
      }
    </div>
  )
}

export default memo(Feedback)