import { atom } from 'recoil'
import { LocalStorageKeys, ROUTES, UUID_DELIMITER } from '../../constants'
import * as Sentry from '@sentry/react'

export const uuidState = atom<string>({
  key: 'uuidState',
  default: '',
  effects_UNSTABLE: [
    ({ setSelf }) => {
      try {
        if (window.location.pathname.includes(ROUTES.PUBLIC_PATH.LOGIN)) {
          const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop as string),
          })
          if (params && params[LocalStorageKeys.InspectionId].includes(UUID_DELIMITER)) {
            const uuid = (params[LocalStorageKeys.InspectionId])
            setSelf(uuid)
            /** FIXME this is unexpected behavior while a selector tries to get the value of uuidState, but this 'GET'
            action triggers uuidState to 'SAVE' a data in localStorage. 
            Moreover if that selector is changed to different page without UUID in the URL. 
            This approach would not work at all.
            TODO refactor this code to decouple the GET and SAVE action
            */
            localStorage.setItem(LocalStorageKeys.UUID, (uuid))
          }
        } else {
          const uuid = localStorage.getItem(LocalStorageKeys.UUID)
          if (uuid) {
            setSelf(uuid)
          }
        }
      } catch (err) {
        Sentry.captureException(err)
      }
    },
  ],
})