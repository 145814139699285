import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationENCommon from '../locales/en/common.json'
import translationNLCommon from '../locales/nl/common.json'
import translationFRCommon from '../locales/fr/common.json'
import translationDECommon from '../locales/de/common.json'
import LANGUAGE_COOKIE from '../constants/languageCookieName'

const resources = {
  en: {
    common: translationENCommon,
  },
  nl: {
    common: translationNLCommon,
  },
  fr: {
    common: translationFRCommon,
  },
  de: {
    common: translationDECommon,
  },
}
i18n.use(LanguageDetector).use(initReactI18next).init({
  resources,
  keySeparator: false,
  fallbackLng: 'en',
  debug: false,
  ns: ['common'],
  defaultNS: 'common',
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'path'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: LANGUAGE_COOKIE,
    lookupFromPathIndex: 0,
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'],
  },
  interpolation: {
    escapeValue: false,
  },
  returnEmptyString: false,
})

export default i18n


