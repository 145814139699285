import { FC } from 'react'
import { FaRegTimesCircle, FaCarCrash } from 'react-icons/fa'
import { Drawer } from 'antd'
import './drawerContent.scss'
import { isErrorDrawerState } from '../../Recoil'
import { useRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'

const ErrorDrawer: FC = () => {
  const { t } = useTranslation()
  const [isErrorDrawerOpenState, setIsErrorDrawerOpenState] = useRecoilState(isErrorDrawerState)
  return (
    <Drawer
      title={ t('error') }
      placement="bottom"
      onClose={() => setIsErrorDrawerOpenState(false)}
      open={isErrorDrawerOpenState}
      height={'auto'}
      closeIcon={<FaRegTimesCircle/>}
      destroyOnClose={true}
      className="drawer-opening error-drawer"
      maskClosable={true}
    >
      <div className="error-drawer">
        <FaCarCrash className="error__icon"/>
        <p>{t('error-drawer-text')}</p>
      </div>
    </Drawer>
  )
}

export default ErrorDrawer