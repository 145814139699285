import { selector } from 'recoil'
import { getOptionsList } from '../../api/apis'
import { damageCategoryCode, keyOptionList } from '../../constants'
import { inspectionIdSelector } from './inspectionIdSelector'
import { availableLanguagesSelector } from './availableLanguagesSelector'

export const inspectionAspectsSelector = selector({
  key: 'inspectionAspectsSelector',
  get: async({ get }) => {
    const options = await getOptionsList({
      keyValue: keyOptionList.CATEGORY,
      value: damageCategoryCode.REQUIRED_IMAGE,
      languageMap: get(availableLanguagesSelector),
      inspectionId: Number(get(inspectionIdSelector))
    }) ?? []
    return options.map((option) => option.code)
  },
})