import i18n from '../utils/i18n'
import { ImageLinksConfig } from './imageLinksConfig'

export const DamageInstructionDataConfig = [
  { text: i18n.t('damage-overview-instruction1'), image: ImageLinksConfig.DAMAGE_INFO_IMAGE_1 },
  { text: i18n.t('damage-overview-instruction2'), image: ImageLinksConfig.DAMAGE_INFO_IMAGE_2 },
]

export const VideoInstructionDataConfig = [
  { text: i18n.t('video-instruction1'), image: ImageLinksConfig.VIDEO_INFO_IMAGE_1 },
  { text: i18n.t('video-instruction2'), image: ImageLinksConfig.VIDEO_INFO_IMAGE_2 },
]

export const OdometerInstructionDataConfig = [{
  text: i18n.t('odo-meter-instruction'), image: ImageLinksConfig.ODO_METER_INFO_IMAGE,
}]

export const cameraBalloonInfoConfig = {
  DAMAGE_OVERVIEW: i18n.t('damage-overview-balloon-text'),
  DAMAGE_DETAIL: i18n.t('damage-detail-balloon-text'),
  TIRES_OVERVIEW: i18n.t('tire-overview-balloon-text'),
  ODO_METER: i18n.t('odo-meter-balloon-text'),
  LICENSE_PLATE: i18n.t('license-plate-balloon-text'),
  VIDEO: i18n.t('video-balloon-text')
}
