import { updateBasic } from '../api/apis'

interface IAcceptationData {
  accepted?: boolean;
  isQrCodeScanned?: boolean;
}
export const triggerUpdateAcceptation = async(inspectionId: number, acceptationData: IAcceptationData) => {
  const updateBaseResponse = await updateBasic(inspectionId, acceptationData)
  return updateBaseResponse?.error?.length === 0 ? Promise.resolve() : Promise.reject()
}
