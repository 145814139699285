import { AxiosError } from 'axios'
import { useState, useEffect } from 'react'

type ApiCallFunction<T> = () => Promise<T>

interface PollingResult<T> {
  data: T | null;
  error: AxiosError | null;
}

const usePolling = <T>(apiCallFunction: ApiCallFunction<T>, interval: number): PollingResult<T> => {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<AxiosError | null>(null)

  useEffect(() => {
    const fetchData = async() => {
      try {
        const response = await apiCallFunction()
        setData(response)
      } catch (err) {
        setError(err)
      }
    }

    fetchData()

    const intervalId = setInterval(fetchData, interval)

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [interval])

  return { data, error }
}

export default usePolling
