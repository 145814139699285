import style from '../_branding-config.scss'

export default {
  '--primary-color': style.jlPrimaryColor,
  '--secondary-color': style.jlSecondaryColor,
  '--base-font-color': style.jlBaseFontColor,
  '--primary-button-color': style.jlSecondaryColor,
  '--secondary-button-color': style.jlPrimaryColor,
  '--quinary-button-border-color': style.jlIconsTextColor,
  '--base-header-color': style.jlSecondaryColor,
  '--base-drawer-header-color': style.jlSecondaryColor,
  '--base-feedback-title-color': style.jlSecondaryColor,
  '--header-title-color': style.jlSecondaryColor,
  '--chapter-header-color': style.jlPrimaryColor,
  '--more-lines-title-color': style.jlSecondaryColor,
  '--more-lines-dot-color': style.jlIconsButtonColor,
  '--progress-bar-color': style.jlPrimaryColor,
  '--icons-header-color': style.jlIconsHeaderColor,
  '--icons-text-color': style.jlIconsTextColor,
  '--icons-button-color': style.jlIconsButtonColor,
  '--primary-background-color': style.jlPrimalyBackgroundColor,
  '--secondary-background-color': style.jlSecondaryBackgroundColor,
  '--tertiary-background-color': style.jlTertiaryBackgroundColor,
  '--page-background-position': style.jlPageBackgroundPosition,
  '--page-background-border-radius': style.jlPageBackgroundBorderRadius,
  '--page-background-transform': style.jlPageBackgroundTransform,
  '--primary-font': style.jlPrimaryFont,
  '--title-font': style.jlPrimaryFont,
  '--form-label-color': style.jlFormLabelColor,
  '--button-font': style.jlPrimaryFont,
  '--primary-font-size': style.jlPrimaryFontSize,
  '--button-font-size': style.jlButtonFontSize,
  '--h4-font-size': style.jlH4FontSize,
  '--base-font-weight': style.jlBaseFontWeight,
  '--bold-font-weight': style.jlBoldFontWeight,
  '--button-font-weight': style.jlBoldFontWeight,
  '--button-text-margin': style.jlButtonTextMargin,
  '--title-text-transform': style.jlTitleTextTransform,
  '--button-text-transform': style.jlButtonTextTransform,
  '--border-list-color': style.jlBaseFontColor,
  '--instruction-page-background-color': style.jlSecondaryColor,
}