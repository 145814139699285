import { atom, selector } from 'recoil'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'
import { getSubmissionStatus } from '../../api/apis'
import { uuidState } from '../Atoms/uuidState'
import * as Sentry from '@sentry/react'

export const hasShortInspectionCompletedState = atom<boolean>({
  key: 'hasShortInspectionCompletedState',
  default:selector({
    key:'hasShortInspectionCompletedState/default',
    get: async({ get }) => {
      try {
        const inspectionId = get(inspectionIdSelector)
        // Avoid API call if inspectionId is 0
        if (inspectionId === '') {
          Sentry.captureException(`this should not happen when inspectionId is 0, check uuid: ${get(uuidState)}`)
          return true
        }

        const response = await getSubmissionStatus(+inspectionId)
        // XXX BE returns error as indication that this inspection has been submitted
        return response?.data?.error?.length > 0
      } catch(error) {
        Sentry.captureException(error)
      }
    },
  }),
})