import { selectorFamily } from 'recoil'
import { damagesState } from '../Atoms/damagesState'
import { damageDefault } from '../../constants/damageDefault'

export const damageSelector = selectorFamily({
  key: 'damageSelector',
  get: (damageId) => ({ get }) => {
    const allDamages = get(damagesState)
    return allDamages.find(item => Number(item.id) === Number(damageId)) ?? damageDefault
  },
})