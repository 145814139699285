
export const damageCategoryCode = {
  DAMAGE_PART: 1,
  DAMAGE_DETAIL: 2,
  REPAIR_TYPE: 3,
  TIRE_TYPE: 4,
  TREAD: 5,
  TIRE_BRAND: 6,
  RIM_TYPE: 8,
  REQUIRED_IMAGE: 9,
  INTERIOR: 101,
  EXTERIOR: 102,
}

export enum SectionCodes {
  Interior = 0,
  Exterior = 1,
  SHORTAGE = 2,
  TIRE = 3,
}

export const damageCategoryFields = {
  DAMAGE_PART: 'damagePart',
  DAMAGE_DETAIL: 'damageDetail',
  REPAIR_TYPE: 'repairType',
  ACCEPTANCE: 'acceptance',
  REPAIR_COSTS: 'repairCosts',
}

export const damageSectionCode = {
  IMAGES_STORAGE: 2,
}

export const keyOptionList = {
  CATEGORY: 'category',
  SECTION: 'section',
  LANGUAGE: 'language',
  GROUP: 'group',
}

export const CheckerGroupList = {
  SAFETY: 0,
  CLIMATE: 1,
  MEDIA: 2,
  COMFORT: 3,
  SECURITY: 4,
  CARGO: 5,
  LUXURY: 6,
}
