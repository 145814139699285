import { selector } from 'recoil'
import * as Sentry from '@sentry/react'
import { getTireConfig } from '../../api/apis'
import { availableLanguagesSelector, inspectionIdSelector } from '../index'

export type TireConfigType = {
  category: number,
  check: boolean,
  childType: any,
  childrenAllowed: [],
  code: string,
  cost: number,
  group: any,
  isActive: any,
  label: string,
  leaseObjectTypeAllowed: object,
  mapping: [],
  module: number,
  parent: any,
  placeHolder: string,
  section: any,
  translation: []
 }

export const tireConfigSelector = selector<TireConfigType[]>({
  key: 'tireConfigSelector',
  get: async({ get }) => {
    try {
      const inspectionId = +get(inspectionIdSelector)
      const languageMap = get(availableLanguagesSelector)
      return getTireConfig(inspectionId, languageMap)
    } catch (error) {
      Sentry.captureException(error)
    }
  }
})