import React, { memo } from 'react'
import { FaCamera } from 'react-icons/fa'
import { useSetRecoilState } from 'recoil'
import TbButton from '../TbButton'
import { cameraModuleDisplayState } from '../../Recoil'
import './photoHandler.scss'

interface Props {
  source: string
  cameraId?: string
  buttonText?: string
}

const PhotoBorderHandler = ({ source, buttonText, cameraId }: Props) => {
  const setCameraModuleDisplay = useSetRecoilState(cameraModuleDisplayState(cameraId))

  const handleTakePictureButtonClick = () => {
    setCameraModuleDisplay(true)
  }

  const imageStyle = cameraId ? 'photo-border-handler__photo background-opacity' : 'photo-border-handler__photo'

  return (
    <div className="photo-border-handler">
      <div className="photo-border-handler__photo-block-outside">
        <div className="photo-border-handler__horizontal-line"></div>
        <div className="photo-border-handler__photo-block-inside">
          <div className="photo-border-handler__vertical-line" ></div>
          <img
            className={imageStyle}
            src={source}
            alt=""
          />
          <div className="photo-border-handler__vertical-line" ></div>
        </div>
        <div className="photo-border-handler__horizontal-line"></div>
      </div>
      { cameraId &&
        <TbButton
          className="photo-border-handler__add-button"
          type="quinary"
          opacity={0.7}
          onClick={handleTakePictureButtonClick}
          icon={<FaCamera />}
        >
          {buttonText}
        </TbButton>
      }
    </div>
  )
}

export default memo(PhotoBorderHandler)