
const DrawerControllerNames = {
  ADD_DAMAGE: 'addDamageDrawer',
  EDIT_DAMAGE: 'editDamageDrawer',
  TIRE_BRAND: 'tireBrand',
}

const instructionIdNames = {
  ODO_METER: 'odoMeterInstruction',
  DAMAGE_OVERVIEW: 'damageOverviewInstruction',
  VIDEO: 'VideoInstruction',
}

const cameraIdNames = {
  FIND_LICENSE_PLATE_STATE_ID: 'findLicensePlate',
  PHOTO_CHECKER: 'photoCheckerCamera',
  INTERIOR_CHECKER: 'interiorCheckerCamera',
  EXTERIOR_CHECKER: 'exteriorCheckerCamera',
  ODO_METER: 'odoMeterCamera',
  TIRES_OVERVIEW: 'tiresOverviewCamera',
  OVERVIEW_PHOTO: 'overviewPhotoCamera',
  DETAIL_PHOTO: 'detailPhotoCamera',
  VIDEO: 'VideoCamera',
}

const DamageDrawerKeys = {
  ADD_DAMAGE: 'addDamage',
  EDIT_DAMAGE: 'editDamage',
  SEE_DAMAGE: 'seeDamage',
}

export { DrawerControllerNames, instructionIdNames, cameraIdNames, DamageDrawerKeys }