
export const photoCameraWidth = 1950

export const photoResolution = {
  minWidth: 1024,
  minHeight: 576,
  idealWidth: 1920,
  idealHeight: 1080,
}

export const externalAspects = {
  videoAspect: 'EXTMED-OVERVIEW-VIDEO',
}