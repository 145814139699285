
export const retrieveDamageData = (currentDamage, imageOverview, allDetailImages) => {
  const retrieveImageData = (imageData) =>  {
    return { type: 1,
      itemBase64: { filename: imageData.labelText, data: imageData.image.substring(imageData.image.indexOf(',') + 1) },
    }
  }

  const retrieveArrayImageData = (arrayImageData) => {
    const finalArray = []
    arrayImageData.map((el, index) => finalArray.push({ type: 2,
      itemBase64: { filename: `${el.labelText} ${index + 1}`, data: el.image.substring(el.image.indexOf(',') + 1) },
    }))
    return finalArray
  }

  const finalMedia = [retrieveImageData(imageOverview), ...retrieveArrayImageData(allDetailImages)]
  return {
    part: currentDamage?.damagePart.code,
    damageType: currentDamage?.damageDetail.code,
    repairType: currentDamage?.repairType.code,
    acceptable: currentDamage?.acceptable,
    initialRepairCosts: currentDamage?.repairCosts,
    remarkInspector: '',
    media: finalMedia,
  }
}
