import React from 'react'
import './videoInspection.scss'

// eslint-disable-next-line max-len
const IFRAME_SOURCE = 'https://guidance.inspektlabs.com/#88c9f38ca4be5bca5659cc28180ffd966bfb97e641987222c003b6c57cd9af55bae73c7ef08032bea846edf9214366bc03751ef2f20762dfeb094a9a0e1ec68b'

function VideoInspection() {
  return (
    <iframe
      className="video-inspection"
      src={IFRAME_SOURCE}
      allow="camera;microphone;geolocation"
      frameBorder="0"
    />
  )
}

export default VideoInspection