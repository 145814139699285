import React from 'react'
import { Button } from 'antd'
import './tbButton.scss'
import { useNavigate } from 'react-router-dom'

interface Props {
  children?: React.ReactNode
  type?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'quinary' | 'link' | 'text'
  icon?: React.ReactNode
  href?: string
  className?: string
  onClick?: (e) => void
  iconPosition?: 'right' | 'left'
  htmlType?: 'submit' | 'button' | 'reset'
  disabled?: boolean
  opacity?: number
}


const TbButton = ({ children, type = 'primary', icon, href,
  onClick, className, iconPosition, htmlType, disabled, opacity }: Props) => {
  const retrieveClassName = () => {
    const defaultClassName = 'tb-button'

    let retrieveClassNameInProps = className ? `${defaultClassName} ${className}` : defaultClassName

    if (type) {
      retrieveClassNameInProps = `${retrieveClassNameInProps} ${defaultClassName}--${type}`
    }

    if (iconPosition === 'right') {
      retrieveClassNameInProps = `${retrieveClassNameInProps} icon-right`
    }

    return retrieveClassNameInProps
  }

  const navigate = useNavigate()

  const clickHandler = (e) => {
    if (onClick) {
      return onClick(e)
    }
    if (href) {
      navigate(href)
    }
  }

  return (
    <Button
      disabled={disabled}
      className={retrieveClassName()}
      icon={icon ?? true}
      type={type === 'link' ? 'link' : null}
      onClick={clickHandler}
      style={opacity && { backgroundColor:`rgba(255, 255, 255, ${opacity})` }}
      htmlType={htmlType}>
      {children}
    </Button>
  )
}

export default TbButton
