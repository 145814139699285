import { FC, useEffect } from 'react'
import Chapter from '../../Layout/Chapter'
import { useTranslation } from 'react-i18next'
import TbButton from '../../components/TbButton'
import { InspectionReason, ROUTES, InspectionStatusConfig, LocalStorageKeys, ImageLinksConfig } from '../../constants'
import { triggerUpdateAcceptation, changeReasonTitle } from '../../helpers'
import { useRecoilValue } from 'recoil'
import {
  flowConfigState, inspectionIdSelector, inspectionReasonTitleState, themeState, isClientSigningFlowState,
} from '../../Recoil'
import FlowStepsList from '../../components/FlowStepsList'
import useNextStepNavigation from '../../hooks/useNextStepNavigation'
import './welcome.scss'
import { useNavigate } from 'react-router-dom'
import i18n from '../../utils/i18n'

const Welcome: FC = () => {
  const { t } = useTranslation()
  const theme = useRecoilValue(themeState)
  const inspectionReasonTitle = useRecoilValue(inspectionReasonTitleState)
  const isClientSigningFlow = useRecoilValue(isClientSigningFlowState)
  const inspectionId = useRecoilValue(inspectionIdSelector)
  const inspectionData = JSON.parse(localStorage?.getItem(LocalStorageKeys.InspectionData))
  const goToNextStep = useNextStepNavigation()
  const navigate = useNavigate()
  const reset = localStorage.getItem(LocalStorageKeys.RESET)
  const flowSteps = useRecoilValue(flowConfigState)
  changeReasonTitle(inspectionReasonTitle, theme)

  useEffect(() => {
    if (isClientSigningFlow === 'true') {
      triggerUpdateAcceptation(+inspectionId, { isQrCodeScanned: true })
    }
    if (inspectionData?.inspectionReason === InspectionReason.END_LEASE_CONTRACT
      && inspectionData?.inspectionStatus === InspectionStatusConfig.EXECUTE_INSPECTION && !reset) {
      navigate(`/${ROUTES.PUBLIC_PATH.THANK_YOU}`, { replace: true })
    }
  }, [])

  const welcomeFlowStep = flowSteps?.find(step => step.path === ROUTES.PRIVATE_PATH.WELCOME)
  const stepsList = welcomeFlowStep?.stepDescriptionKeys
    ? welcomeFlowStep?.stepDescriptionKeys?.split(', ').map(item => t(item)) : null

  return (
    <div className="welcome">
      <Chapter
        image={ImageLinksConfig.WELCOME_PAGE_IMAGE}
        title={i18n.t('welcome-page-title')}
        description={ t(welcomeFlowStep?.description) }
        timeTitle={
          t('short-inspection-flow-indication_other', { count: welcomeFlowStep?.passageTime })
        }
        hasRemainingTimeIndication={true}
        hasImagePadding={false}
      />
      { stepsList &&
        <div className="welcome__flow-steps">
          <FlowStepsList flowStepsList={stepsList} title={t('title-flow-steps-list')}/>
        </div>
      }
      <div className="footer-button">
        <TbButton
          type="primary"
          htmlType="button"
          onClick={goToNextStep}
        >
          { t('continue-button-text') }
        </TbButton>
      </div>
    </div>
  )
}

export default Welcome
