import { cameraIdNames } from './idNames'
import i18n from '../utils/i18n'


export const ImageConfirmation = [
  cameraIdNames.OVERVIEW_PHOTO,
  cameraIdNames.DETAIL_PHOTO,
]

export interface IImageInfo {
  cameraId: string,
  title: string,
  description: string,
}

export const imageConfirmInfo = {
  [cameraIdNames.OVERVIEW_PHOTO]: {
    cameraId: 'overviewPhotoCamera',
    title: i18n.t('damage-overview-title'),
    description: i18n.t('damage-overview-confirmation-text'),
  },
  [cameraIdNames.DETAIL_PHOTO]: {
    cameraId: 'detailPhotoCamera',
    title: i18n.t('damage-detail-confirm-title'),
    description: i18n.t('damage-detail-confirmation-text'),
  },
}
