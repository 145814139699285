import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  leaseIdSelector, inspectionIdSelector, inspectionAspectsSelector, themeState,
  availableLanguagesSelector, flowConfigState, isClientSigningFlowState, inspectionStatusState, inspectionReasonState
} from '../../Recoil'
import { LocalStorageKeys, ROUTES, UUID_DELIMITER, LANGUAGE_COOKIE, langMap } from '../../constants'
import { useTranslation } from 'react-i18next'
import { getFlowSteps, getInspectionData } from '../../api/apis'
import './login.scss'

// FIXME route welcome would trigger this component to be rerendered, which
// causes the navigation twice and unnecessary computing
const Login = () => {
  const { t } = useTranslation()
  const [query] = useSearchParams()
  const inspectionIdFromQuery = query.get(LocalStorageKeys.InspectionId)
  const isClientSigningFlow = query.get(LocalStorageKeys.CUSTOMER_SIGNING)
  const setCustomerSigningFlow = useSetRecoilState(isClientSigningFlowState)
  const inspectionId = useRecoilValue(inspectionIdSelector)
  const navigate = useNavigate()
  const leaseId = useRecoilValue(leaseIdSelector)
  const availableLanguages = useRecoilValue(availableLanguagesSelector)
  const requiredAspects = useRecoilValue(inspectionAspectsSelector)
  const setTheme = useSetRecoilState(themeState)
  const setInspectionReason = useSetRecoilState(inspectionReasonState)
  const setInspectionStatus = useSetRecoilState(inspectionStatusState)
  const [flowSteps, setFlowSteps] = useRecoilState(flowConfigState)
  const inspectionData = JSON.parse(localStorage.getItem(LocalStorageKeys.InspectionData))

  useEffect(() => {
    if (inspectionIdFromQuery) {
      if (inspectionIdFromQuery.includes(UUID_DELIMITER)) {
        localStorage.setItem(LocalStorageKeys.RequiredAspects, JSON.stringify(requiredAspects))
      }
      const defaultLanguage = availableLanguages?.find(el => el.default)?.code.toLowerCase() ?? langMap.DEFAULT_LANGUAGE
      if (defaultLanguage !== localStorage.getItem(LANGUAGE_COOKIE)) {
        localStorage.setItem(LANGUAGE_COOKIE, defaultLanguage)
        window.location.reload()
      }
    }
  }, [inspectionIdFromQuery])

  useEffect(() => {
    if (inspectionId) {
      getInspectionData(inspectionId, availableLanguages)
    }
    setTheme(localStorage.getItem(LocalStorageKeys.THEME))
    if (inspectionId && !flowSteps?.length) {
      getFlowSteps(Number(inspectionId)).then(res => setFlowSteps(res))
    }
  }, [inspectionId])

  useEffect(() => {
    setCustomerSigningFlow(isClientSigningFlow)

    const queryString = query.toString()
    if (queryString.includes(LocalStorageKeys.RESET)) {
      localStorage.setItem(LocalStorageKeys.RESET, 'true')
    }
  }, [])

  useEffect(() => {
    if (leaseId && flowSteps?.length) {
      navigate(`/${flowSteps?.[0]?.path}`)
    } else if (leaseId && !flowSteps?.[0]?.path) {
      navigate(`/${ROUTES.PUBLIC_PATH.THANK_YOU}`, { replace: true })
    }
  }, [leaseId, flowSteps])

  useEffect(() => {
    setInspectionReason(inspectionData?.inspectionReason)
    setInspectionStatus(inspectionData?.inspectionStatus)
  }, [inspectionData])

  const handleClickReLogin  = () => {
    if (!inspectionIdFromQuery && !inspectionId) {
      alert(t('you-need-to-have-inspection-id-in-the-query-parameter'))
    } else {
      navigate(`/${ROUTES.PUBLIC_PATH.LOGIN}?inspectionid=${inspectionId}`, { replace: true })
    }
  }

  return (
    <div className="login">
      <div>Login</div>
      <button className="reload-btn" onClick={handleClickReLogin}>{t('log-back-in')}</button>
    </div>
  )
}

export default Login