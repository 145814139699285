import React, { FC } from 'react'
import BalloonTail from '../../Assets/svg/balloon-tail.svg'
import './infoBalloon.scss'

interface IInfoBalloon {
  cameraBalloonInfo: string;
}

export const InfoBalloon: FC<IInfoBalloon> = ({ cameraBalloonInfo }) => {

  return (
    <div className="info-balloon">
      <span className="info-balloon__balloon-body">{ cameraBalloonInfo }</span>
      <img className="info-balloon__balloon-tail" src={BalloonTail} alt=""/>
    </div>
  )
}