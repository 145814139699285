import React, { FC } from 'react'
import TbButton from '../TbButton'
import { FaUndo } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { IImageInfo } from '../../constants'
import './imageConfirmation.scss'

interface IImageConfirmation {
  imageInfo: IImageInfo,
  image: string,
  handleShowCamera: (value) => void,
  imageConfirmClose: () => void,
}

export const ImageConfirmation: FC<IImageConfirmation> = ({
  imageInfo,
  image,
  handleShowCamera,
  imageConfirmClose,
}) => {
  const { t } = useTranslation()

  return (
    <div className="image-confirm">
      <div className="image-confirm__photo-holder">
        <img src={image} alt="" className="image-confirm__photo"/>
      </div>
      <div className="image-confirm__info-holder">
        <h4>{ imageInfo?.title }</h4>
        <p>{ imageInfo?.description }</p>
        <div className="image-confirm__buttons">
          <TbButton type="link"
            className="image-confirm__retake-image"
            onClick={() => handleShowCamera(true)}>
            <FaUndo/> {t('photo-module-retake')}
          </TbButton>
          <TbButton type="primary" onClick={imageConfirmClose}> {t('confirm')} </TbButton>
        </div>
      </div>
    </div>
  )
}