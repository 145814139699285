import TbButton from '../../components/TbButton'
import React, { FC } from 'react'
import './footer.scss'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../constants'
import { useRecoilValue } from 'recoil'
import { isFooterDisabledState } from '../../Recoil'
import { convertPathToMatchRouteConfig } from '../../helpers'

const DEFAULT_FOOTER = ROUTES.PRIVATE_PATH.ROOT

const Footer: FC = () => {
  const location = useLocation()
  const pathName = location.pathname.substring(1)
  const navigate = useNavigate()
  const params = useParams()
  const isFooterEnabled = useRecoilValue(isFooterDisabledState)

  const currentRouteConfig = ROUTES.ROUTES_CONFIG.find(
    route => {
      if (pathName.length === 0) {
        return true
      } else {
        return params.id
          ? route.path === convertPathToMatchRouteConfig(location)
          : route.path === pathName
      }
    },
  )

  const getText = () => currentRouteConfig?.footerText
  const getHref = () => currentRouteConfig?.footerHref ?? DEFAULT_FOOTER

  const handelClick = () => {
    if (isFooterEnabled) {
      navigate(getHref())
    }
  }


  return getText()
    ? (
      <footer className="footer">
        <TbButton type="primary" onClick={handelClick}>{getText()}</TbButton>
      </footer>
    )
    : null
}

export default Footer
