import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../constants'
import { FaRegClock } from 'react-icons/fa'
import './chapter.scss'
import { useTranslation } from 'react-i18next'
import styleVars from '../../styles/_branding-config.scss'

const IMAGE_DIMENSION = styleVars.chapterImageDimension.split('px')[0]

interface Props {
  image: string
  title: string
  description: React.ReactNode | string | null,
  timeTitle?: string
  hasRemainingTimeIndication?: boolean
  hasImagePadding?: boolean
}

const Chapter: FC<Props> = ({
  image,
  title,
  description,
  timeTitle,
  hasRemainingTimeIndication,
  hasImagePadding,
}) => {
  const { t } = useTranslation()
  const location = useLocation()

  const isThanksBackground = ROUTES.ROUTES_CONFIG.find(route =>
    route.path === location.pathname.substring(1))?.hasThankYouBackground

  const DEFAULT_BACKGROUND_CLASS = 'chapter__background'
  const DEFAULT_CHAPTER_IMAGE_CLASS = 'chapter__image-holder'

  const chapterBackgroundClasses = isThanksBackground ? `${DEFAULT_BACKGROUND_CLASS} thanks` : DEFAULT_BACKGROUND_CLASS

  const chapterImageClasses = () => hasImagePadding
    ? DEFAULT_CHAPTER_IMAGE_CLASS
    : `${DEFAULT_CHAPTER_IMAGE_CLASS} no-padding`

  return (
    <div className="chapter">
      <div className={chapterBackgroundClasses}>
        <div className={chapterImageClasses()}>
          <img src={image} className="chapter__image" width={IMAGE_DIMENSION} height={IMAGE_DIMENSION} alt=""/>
        </div>
      </div>
      { hasRemainingTimeIndication && <div className="chapter__remaining-time">
        <div className="icon-wrapper">
          <FaRegClock />
        </div>
        <span>{ timeTitle }</span>
      </div>
      }
      <section className="chapter__content">
        <h1 className="chapter__title">{ t(title) }</h1>
        { description && <p className="chapter__description">{ description }</p> }
      </section>
    </div>
  )
}

export default Chapter