import { atom, selector } from 'recoil'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'
import { getFlowSteps } from '../../api/apis'
import { tokenState } from './tokenState'

export const flowConfigState = atom<any>({
  key: 'flowConfigState',
  default: selector({
    key: 'flowConfigState/default',
    get: async({ get }) => {
      const token = get(tokenState)
      const inspectionId = get(inspectionIdSelector)
      if (inspectionId && token) {
        const allSteps = await getFlowSteps(+inspectionId)
        return allSteps
      }
      return []
    },
  }),
})
