import { useNavigate, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { flowConfigState } from '../Recoil'
import { FlowStepType } from '../constants'

const useNextStepNavigation = (): (() => void) => {
  const navigate = useNavigate()
  const location = useLocation()
  const flowSteps = useRecoilValue(flowConfigState)
  const currentPath = location.pathname
  const nextStep = () => {
    const index = flowSteps?.findIndex((step: FlowStepType) => step.path === currentPath.substring(1))
    if (index < 0 || index === flowSteps.length - 1) {
      return undefined
    } else {
      if (flowSteps[index + 1]?.path) {
        navigate(`/${flowSteps[index + 1].path}`)
      }
    }
  }

  return nextStep
}

export default useNextStepNavigation