import { selector } from 'recoil'
import { getInspectionIdByUuid } from '../../api/apis'
import { uuidState } from '../Atoms/uuidState'
import { tokenState } from '../Atoms/tokenState'

export const inspectionIdSelector = selector<string>({
  key: 'inspectionIdSelector',
  get: ({ get }) => {
    const uuid = get(uuidState)
    const token = get(tokenState)
    if (uuid && token) {
      return getInspectionIdByUuid(uuid)
    }
    return ''
  },
})