const LanguageMapping = {
  'nl': 0,
  'en': 1,
  'fr': 2,
  'de': 3,
}

export default {
  LanguageMapping,
  DEFAULT_LANGUAGE: 'en',
}
